import React from 'react';
import {
  AddConsultantButton,
  AddIcon,
  BackFullIcon,
  Container,
  Content,
  Header,
  HeaderActions,
  HeaderBack,
  HeaderInfo,
  HeaderTitle,
  LoadRow,
} from './styles';
import MenuBar from '../../../../components/menu-bar';
import Search from '../../../../components/search';
import { toast } from 'react-toastify';
import {
  Card,
  ControlledBoard,
  KanbanBoard,
  moveCard,
  OnDragEndNotification,
} from '@caldwell619/react-kanban';
import '@caldwell619/react-kanban/dist/styles.css'; // import here for "builtin" styles
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { PersonalClientCRUD } from '../../../../services/requests/personal-client';
import Load from '../../../../components/load';
import { CONST_API, CONST_APPLICANT_KAMBAM_STEP } from '../../../../utils/CONSTANTS';
import CardUserInfo from '../../../../components/cards/user-info';
import CandidateModal from '../../../../components/modais/applicant';
import { ApplicantCRUD } from '../../../../services/requests/applicant';
import WarnModal from '../../../../components/modais/warn';
import { ApplicantData } from '../../../../services/requests/applicant/index.d';
import Cookies from 'js-cookie';
import PDFmodal from '../../../../components/modais/pdf';

const applicantCRUD = new ApplicantCRUD();

const RecrutamentKambamPage: React.FC = () => {
  // Params
  const location = useLocation();
  const navigation = useNavigate();
  const { id } = useParams();
  const { colors } = useTheme();
  const { name } = location.state || {};
  
  // Refs
  const isApiRunning = React.useRef(false);

  // States
  const [search, setSearch] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [currentClient, setCurrentClient] = React.useState<ApplicantData>();
  const [showModal, setShowModal] = React.useState(false);
  const [showWarn, setShowWarn] = React.useState(false);
  const [showDeleteOrTalent, setDeleteOrTalent] = React.useState(false);
  const [controlledBoard, setBoard] = React.useState<KanbanBoard<any>>({
    columns: [],
  });
  const [showPDF, setPDFModal] = React.useState(false);
  const [showChoosePDF, setChoosePDF] = React.useState(false);

  const handleCardMove: OnDragEndNotification<Card> = (
    _card,
    source,
    destination,
  ) => {
    setBoard(currentBoard => {
      handleUpdateVancancie(Number(_card.id), Number(destination?.toColumnId));
      return moveCard(currentBoard, source, destination);
    });
  };

  const refreshData = React.useCallback(async (page?: number) => {
    if (isApiRunning.current) return;
    try {
      isApiRunning.current = true;
      setLoading(true);
      // currentPage.current = page || 1;
      const { data, status, error } = await applicantCRUD.read({
        page,
        per_page: 10,
        corporate_client_id: id
      });

      console.log(data, error);

      if (error) {
        throw new Error(error.message.description);
      }

      if (status === 200 && data) {
        let currentData = KAMBAM_DATA;

        data.Applicant.forEach(item => {
          currentData.columns = currentData.columns.map(column => {
            if (column.title === item.kanban_step) {
              return {
                ...column,
                cards: [...column.cards, item],
              };
            } else if (item.kanban_step === null && column.id === 0) {
              return {
                ...column,
                cards: [...column.cards, item],
              };
            }
            return column;
          });
        });

        setBoard(currentData);
      }
    } catch (e) {
      alert(e);
    } finally {
      isApiRunning.current = false;
      setLoading(false);
    }
  }, []);

  const handleUpdateVancancie = React.useCallback(
    async (idCard: number, columnId: number) => {
      let kambamStep: string;
      let currentKambam: string;
      kambamStep = KAMBAM_DATA.columns[columnId].title;

      if (
        CONST_APPLICANT_KAMBAM_STEP.findIndex(
          item => item.label.toLowerCase() === kambamStep.toLocaleLowerCase(),
        ) >= 0
      ) {
        currentKambam =
          CONST_APPLICANT_KAMBAM_STEP[
            CONST_APPLICANT_KAMBAM_STEP.findIndex(
              item =>
                item.label.toLowerCase() === kambamStep.toLocaleLowerCase(),
            )
          ].keys;
      } else {
        currentKambam = kambamStep;
      }

      try {
        const {
          data,
          status: Status,
          error,
        } = await applicantCRUD.update({
          id: idCard,
          kanban_step: currentKambam,
        });

        if (error) throw new Error(error.message.description);
        if (Status === 200 && data) {
          toast('Passo no Kambam atualizado com sucesso.');
        }
      } catch (e) {
        alert(e);
      }
    },
    [],
  );

  const handleDeleteApplicant = React.useCallback(async (id: number) => {
    try {
      console.log(id);
      const { status, data, error } = await applicantCRUD.delete({ id });

      if (error) {
        throw new Error(error.message.description);
      }

      if (status === 200 && data) {
        toast('Aplicante deletado com sucesso.');
      }
    } catch (e) {
      alert(e);
    }
  }, []);

  const downloadPDF = React.useCallback(async (name: string, id: number) => {
    try {
      const response = await fetch(`${CONST_API.protocol}${CONST_API.baseUrl}/applicant/attachment/view/${id}`, {
        method: 'GET',
        headers: {
          Accept: 'application/pdf',
          Authorization: `Bearer ${Cookies.get('token')}`
        }
      })

      if(!response.ok) {
        throw new Error('Nenhum pdf encontrado.')
      }

      const pdf = await response.blob();

      const url = URL.createObjectURL(pdf);
      const link = document.createElement('a');

      link.href = url;
      link.download = `${name}.pdf`; 
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (e) {
      const {message} = e as Error
      toast(message);
    } 
  }, []);


  React.useEffect(() => {
    refreshData();
  }, []);

  return (
    <Container>
      <MenuBar currentPage="Recrutamento" />
      <Content>
        <Header>
          <HeaderBack href="/recrutamento">
            <BackFullIcon />
          </HeaderBack>
          <HeaderInfo>
            <HeaderTitle>Recrutamento e seleção - {name}</HeaderTitle>
            <HeaderActions>
              <Search
                value={search}
                setValue={setSearch}
                placeholder="Pesquisar clientes"
              />
              <AddConsultantButton
                title="Adicionar cliente"
                iconRight={AddIcon}
                callback={() => setShowModal(true)}
              />
            </HeaderActions>
          </HeaderInfo>
        </Header>

        {loading ? (
          <LoadRow>
            <Load
              customStyle={{
                color: colors.secondaryDark,
              }}
              size="extraLarge"
            />
          </LoadRow>
        ) : (
          <>
            {controlledBoard.columns.length > 0 ? (
              <KambamStyles>
                <ControlledBoard
                  disableColumnDrag
                  allowAddCard={false}
                  allowAddColumn={false}
                  allowRenameColumn={false}
                  onCardDragEnd={handleCardMove}
                  onCardRemove={({ board, card, column }) => {
                    console.log({ board, card, column });
                  }}
                  allowRemoveColumn={false}
                  allowRemoveCard={false}
                  renderCard={(card: any, options) => (
                    <CardUserInfo
                      editCallback={() => {
                        let cardTs = card as any as ApplicantData
                        setCurrentClient(cardTs);
                        setShowModal(true);
                      }}
                      deleteCallback={() => {
                        setShowWarn(true)
                        // handleDeleteApplicant(card.id);
                        // refreshData();
                      }}
                      customStyle={{marginRight:0}}
                      talentPool={{
                        name: card.name,
                        budget: card.budget,
                        email: card.email,
                        occupation: card.occupation,
                        image: card.image,
                        local: card.local,
                        id: 1,
                      }}
                      pdfCallback={() => {
                        setCurrentClient(card);
                        setChoosePDF(true);
                      }}
                    />
                  )}>
                  {controlledBoard}
                </ControlledBoard>
              </KambamStyles>
            ) : (
              <HeaderTitle>Nenhum cliente cadastrado...</HeaderTitle>
            )}
          </>
        )}
      </Content>
      {showModal && (
        <CandidateModal
          onClose={() => {
            setShowModal(false);
          }}
          isApplicant
          talentPool={currentClient}
          corporateClientId={id}
        />
      )}
       {showWarn && (
        <WarnModal
          title="Remover Aplicante"
          description={`Certeza que quer remover o aplicante${currentClient?.name}?`}
          confirmCallback={() => {
            setShowWarn(false);
            setDeleteOrTalent(true)
          }}
          declineCallback={() => {
            setCurrentClient(undefined);
            setShowWarn(false);
          }}
          declineButtonTitle='Não, manter.'
          confirmButtonTitle='Sim, remover.'
        />
      )}
       {showDeleteOrTalent && (
        <WarnModal
          title="Apagar ou Mover?"
          description={`Quer remover ou enviar para o banco de talentos o  aplicante ${currentClient?.name}?`}
          confirmCallback={() => {
           
            setCurrentClient(undefined);
            setShowWarn(false);
          }}
          declineCallback={() => {
            setCurrentClient(undefined);
            setShowWarn(false);
            handleDeleteApplicant(currentClient?.id || 0);
          }}
          declineButtonTitle='Não, manter.'
          confirmButtonTitle='Sim, remover.'
        />
      )}
      {showPDF && (
        <PDFmodal
          onClose={() => {
            setPDFModal(false);
            setCurrentClient(undefined);
          }}
          currentUserId={currentClient?.id}
          userType='applicant'
        />
      )}

      {showChoosePDF && (
        <WarnModal
         title="O que deseja fazer?"
         description={`Deseja visualizar ou baixar o curriculo de ${currentClient?.name}?`}
          confirmCallback={() => {
           setPDFModal(true)
           setChoosePDF(false)
          }}
          declineCallback={() => {
            downloadPDF(currentClient?.name || '' , currentClient?.id || 0 )
          }}
          confirmButtonTitle='Visualizar PDF'
          confirmButtonBgColor={colors.primaryLight}
          declineButtonTitle='Baixar o PDF'
          declineButtonBgColor={colors.secondary}
          declineButtonTextColor={colors.white}
          onClose={() => {
            setChoosePDF(false)
            setCurrentClient(undefined);
          }}
       />
      )}

    </Container>
  );
};

export default RecrutamentKambamPage;

const KambamStyles = styled('div')`
  flex: 1;
  display: flex;
  & * {
    /* font-family: 'Segoe' !important; */
  }
  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Inter' !important;
  }
  & .react-kanban-column {
    padding: 0;
    background-color: transparent;
    margin-right: 30px;
    &:nth-child(1) {
      & .react-kanban-column-header {
        background-color: #7f2c7c;
      }
    }
    &:nth-child(2) {
      & .react-kanban-column-header {
        background-color: #4a94f8;
      }
    }
    &:nth-child(3) {
      & .react-kanban-column-header {
        background-color: #56c991;
      }
    }
    &:nth-child(4) {
      & .react-kanban-column-header {
        background-color: #ff6262;
      }
    }
    &:nth-child(5) {
      & .react-kanban-column-header {
        background-color: #b55bcc;
      }
    }
    &:nth-child(6) {
      & .react-kanban-column-header {
        background-color: #8075ff;
      }
    }
    &:nth-child(7) {
      & .react-kanban-column-header {
        background-color: #bfb060;
      }
    }
    &:nth-child(8) {
      & .react-kanban-column-header {
        background-color: #ff72f1;
      }
    }
    &:nth-child(9) {
      & .react-kanban-column-header {
        background-color: #56c991;
      }
    }
  }
  & .react-kanban-column-header {
    border-radius: 5px;
    padding: 12px;
    margin-bottom: 25px;
    & span {
      color: white;
    }
  }
  & .react-kanban-card {
    margin-bottom: 20px;
  }
`;

const KAMBAM_DATA: {
  columns: {
    id: number;
    title: string;
    cards: any;
  }[];
} = {
  columns: [
    {
      id: 0,
      title: 'Abertura da vaga',
      cards: [],
    },
    {
      id: 1,
      title: 'Recebimento de currículo',
      cards: [],
    },
    {
      id: 2,
      title: 'Pré-avaliação',
      cards: [],
    },
    {
      id: 3,
      title: 'Entrevista por competência',
      cards: [],
    },
    {
      id: 4,
      title: 'Teste',
      cards: [],
    },
    {
      id: 5,
      title: 'Relatório',
      cards: [],
    },
    {
      id: 6,
      title: 'Entrevista técnica',
      cards: [],
    },
  ],
};

export interface CustomCard extends Card {
  id: number;
  name: string;
  image: string | null;
  occupation: string;
  status: string;
  client_type: string;
  kanban_step: string;
  budget: string;
  cpf: string;
  local: string;
  advisor_relation: {
    id: number;
    image: string | null;
    name: string;
  } | null;
  vacancy_matching_quantity: number;
  onClick?: () => void;
}

import React from 'react';
import { Input, InputContainer, LoadArea, SearchIcon } from './styles';

import typ from './index.d';
import Load from '../load';
import { theme } from '../../global/styles';

const Search: React.FC<typ.Props> = ({
  placeholder,
  setValue,
  value,
  isLoading,
  customStyle,
}) => {
  return (
    <InputContainer style={customStyle}>
      {
        isLoading
        ?
          <LoadArea>
            <Load size='small' customStyle={{color: theme.colors.secondary, top: -8, marginLeft: -10}}/>
          </LoadArea>
        :
          <SearchIcon />
      }
      <Input
        placeholder={placeholder}
        onChange={event => setValue(event.target.value)}
        value={value}
        type="text"
      />
    </InputContainer>
  );
};

export default Search;

import styled from 'styled-components';
import CardModal from '../../modal';
import { IconDocument, IconPhoto } from '../../icons';

export const Modal = styled(CardModal)``;

export const PDFContent = styled.div`
  height: 700px; 
  overflow: 'scroll'; 
  align-items: 'flex-start';
  justify-content: 'flex-start';
  width: 100%
`;

export const ImageArea = styled.button`
  width: 166px;
  height: 166px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
export const ImageInput = styled.input`
  display: none;
`;
export const DocumentInput = styled.input`
  display: none;
`;

export const Image = styled.img.attrs({ className: 'img-responsive' })`
  overflow: hidden;
  border-radius: 9px;
  width: 166px;
  height: 166px;
`;

export const DocumentArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
export const DocumentButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  justify-content: center;
  align-items: center;
  display: flex;
  width: 48px;
  height: 48px;
  margin: 0 0 8px 10px;
  border-radius: 5px;
  transition: background-color 350ms ease;

  @media only screen and (min-width: 1024px) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.secondary};
    }
  }
`;

export const PdfTitle = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.textSecondary};
  margin: 0 0 15px;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  text-align: left;
  width: 100%;
`;

// icones
export const DocumentIcon = styled(IconDocument)`
  fill: none;
  color: ${({ theme }) => theme.colors.white};
`;

export const PhotoIcon = styled(IconPhoto)`
  position: absolute;
  z-index: 2;
  bottom: -8px;
  right: -9px;
  fill: none;
`;

import Cookies from 'js-cookie';
import { axiosManager } from '../../axios-manager';
import ApplicantCRUDProps from './index.d';

class ApplicantCRUD {
  private readonly token;

  constructor() {
    this.token = Cookies.get('token') || '';
  }

  create({
    budget,
    email,
    local,
    name,
    occupation,
    attachment,
    image,
    kanban_step = 'VACANCY_OPENING',
    corporate_client_id
  }: ApplicantCRUDProps.createParams) {
    const data = new FormData();
    if (name) data.append('name', name);
    if (email) data.append('email', email);
    if (budget) data.append('budget', budget);
    if (local) data.append('local', local);
    if (occupation) data.append('occupation', occupation);
    if (image) data.append('image', image);
    if (attachment) data.append('attachment', attachment);
    if (kanban_step) data.append('kanban_step', kanban_step);
    if (corporate_client_id) data.append('corporate_client_id', corporate_client_id);

    return axiosManager<ApplicantCRUDProps.createReturn>({
      method: 'post',
      url: `/applicant/create`,
      auth: this.token,
      payload: data,
    });
  }

  read({ page = 1, per_page = 40, corporate_client_id = '0' }: ApplicantCRUDProps.readParams) {
    let url = `/applicant/list?page=${page}&per_page=${per_page}&corporate_client_id=${corporate_client_id}`;

    return axiosManager<ApplicantCRUDProps.readReturn>({
      method: 'get',
      url,
      auth: this.token,
      payload: null,
    });
  }

  update({
    name,
    email,
    budget,
    local,
    occupation,
    image,
    attachment,
    kanban_step,
    id,
  }: ApplicantCRUDProps.updateParams) {
    const data = new FormData();
    if (name) data.append('name', name);
    if (email) data.append('email', email);
    if (budget) data.append('budget', budget);
    if (local) data.append('local', local);
    if (occupation) data.append('occupation', occupation);
    if (image) data.append('image', image);
    if (attachment) data.append('attachment', attachment);
    if (kanban_step) data.append('kanban_step', kanban_step);

    return axiosManager<ApplicantCRUDProps.updateReturn>({
      method: 'put',
      url: `/applicant/update/${id}`,
      auth: this.token,
      payload: data,
    });
  }

  delete({ id }: ApplicantCRUDProps.deleteParams) {
    return axiosManager<ApplicantCRUDProps.deleteReturn>({
      method: 'delete',
      url: `/applicant/delete/${id}`,
      auth: this.token,
      payload: null,
    });
  }

  view({ id }: ApplicantCRUDProps.viewParams) {
    return axiosManager<ApplicantCRUDProps.viewReturn>({
      method: 'get',
      url: `/applicant/attachment/view/16`,
      auth: this.token,
      payload: null,
    });
  }
  moveTo({
    name,
    email,
    budget,
    local,
    occupation,
    image,
    attachment,
    kanban_step,
    id,
  }: ApplicantCRUDProps.updateParams) {
    const data = new FormData();
    if (name) data.append('name', name);
    if (email) data.append('email', email);
    if (budget) data.append('budget', budget);
    if (local) data.append('local', local);
    if (occupation) data.append('occupation', occupation);
    if (image) data.append('image', image);
    if (attachment) data.append('attachment', attachment);
    if (kanban_step) data.append('kanban_step', kanban_step);

    return axiosManager<ApplicantCRUDProps.updateReturn>({
      method: 'put',
      url: `/applicant/moveto/talent-pool/${id}`,
      auth: this.token,
      payload: data,
    });
  }
}

export { ApplicantCRUD };

import styled from 'styled-components';
import { IconAdd, IconBackFull } from '../../../../components/icons';
import Button from '../../../../components/button';
import InputComponent from '../../../../components/input';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px;
  height: 100vh;
  /* background-color: ${({ theme }) => theme.colors.grayUltraHigh} */
  background-color: #f9f9f9;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow-x: scroll;
`;

export const Header = styled.div`
  padding: 25px 0px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayUltraHigh};
  margin-bottom: 30px;
`;

export const HeaderBack = styled.a`
  margin-bottom: 107px;
  cursor: pointer;
`;

export const HeaderInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

export const HeaderTitle = styled.h2`
  color: ${({ theme }) => theme.colors.textSecondary};
  font-family: ${({ theme }) => theme.fontFamily.inter.semiBold};
  font-size: 20px;
  margin: 0px;
`;

export const VacanciesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const SectionTitle = styled.h2`
  color: ${({ theme }) => theme.colors.textSecondary};
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  font-size: 18px;
  margin: 0px 0px 12px;
  width: 100%;
  text-align: left;
`;

export const InputContainer = styled.div`
  width: calc(100% / 2 - 20px);
`;

export const InputsVancacieRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
`;

export const InputsVancacie = styled(InputComponent)``;

export const InputsContactRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

export const KeyWordsArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0px 0 0;
`;

export const KeyWord = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  color: #3a3577;
  font-size: 15px;
  background-color: #dfddff;
  border: 1px solid #8075ff;
  border-radius: 4px;
  padding: 3px 9px;
  margin-right: 10px;
  cursor: pointer;
  transition: background-color 350ms ease;
  @media only screen and (min-width: 1024px) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const ButtonAddVancacie = styled(Button).attrs({
  customStyle: { maxWidth: 340 },
})``;

export const InputKeyWord = styled.div`
  position: relative;
`;
export const ButtonAddKeyWord = styled.div`
  background-color: ${({theme}) => theme.colors.secondaryDark};
  border-radius: 4px;
  position: absolute;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 8px;
  bottom: 0px;
  right: 10px;
  cursor: pointer;
  transition: background-color 350ms ease;
  @media only screen and (min-width: 1024px) {
    &:hover {
      background-color: ${({theme}) => theme.colors.primary};
    }
  }
`;

// Icons
export const BackFullIcon = styled(IconBackFull).attrs({
  width: 74,
  height: 22,
})`
  color: #8f8f8f;
  fill: none;
  transition: color 350ms ease;
  @media only screen and (min-width: 1024px) {
    &:hover {
      color: ${({ theme }) => theme.colors.secondaryDark};
    }
  }
`;

export const AddIcon = styled(IconAdd).attrs({
  width: 17,
  height: 17,
})`
  fill: none;
`;

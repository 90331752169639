import React from 'react';
import { Container, Description, DescriptionArea, Title } from './styles';
import { useTheme } from 'styled-components';

const Page404: React.FC = () => {
  const { colors } = useTheme();
  return (
    <Container>
      <Title>404 - Página não encontrada</Title>
      <Description>Parece que a página que você procura não existe.</Description>
    </Container>
  );
};

export default Page404;

import styled from 'styled-components';
import CardModal from '../../modal';
import {
  IconAmount,
  IconCpf,
  IconEdit,
  IconMail,
  IconMatchs,
  IconMoney,
  IconPhone,
  IconPin,
  IconTrash,
  IconUser,
} from '../../icons';
import Button from '../../../components/button';
import Load from '../../load';

export const Modal = styled(CardModal)``;

export const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 10px;
  margin-bottom: 20px;
`;

export const Code = styled.h2`
  margin: 0px 0px 0px;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.textSecondary};
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
`;

export const ActionsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonComp = styled.button``;

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: center;
`;

export const InfoArea = styled.div`
  width: calc(100% / 2);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Name = styled.h3`
  margin: 0px 0px 6px;
  font-size: 32px;
  color: ${({ theme }) => theme.colors.secondaryDark};
  font-family: ${({ theme }) => theme.fontFamily.inter.semiBold};
  line-height: 36.57px;
`;

export const Occupation = styled.h3`
  margin: 0px 0px 6px;
  font-size: 20px;
  color: #959595;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
`;

export const Description = styled.h3`
  margin: 0px 0px 10px;
  font-size: 13px;
  color: #525252;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
`;

export const RowTitle = styled.h3`
  margin: 0px 0px 0px;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.textSecondary};
  font-family: ${({ theme }) => theme.fontFamily.inter.semiBold};
`;

export const SeeMoreLink = styled.a`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.inter.bold};
  text-decoration: underline;
  transition: color 350ms ease;
  margin: 0 0 0px 10px;

  @media only screen and (min-width: 1024px) {
    &:hover {
      color: ${({ theme }) => theme.colors.secondary};
    }
  }
`;

export const InfoSvgRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

export const ContactSvgRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

export const Amount = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 16px;
`;

export const Salary = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  color: ${({ theme }) => theme.colors.secondaryDark};
  font-size: 16px;
`;

export const Contact = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 16px;
`;

export const KeyWordsArea = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 18px 0 0;
`;

export const KeyWord = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  color: #3a3577;
  font-size: 15px;
  background-color: #dfddff;
  border: 1px solid #8075ff;
  border-radius: 4px;
  padding: 3px 9px;
  margin: 0 3px 5px;
`;

export const MatchArea = styled.div`
  width: calc(100% / 2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #eef0f4;
  padding: 10px;
  border-radius: 10px;
`;

export const MatchCard = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  box-shadow: 0px 8px 24px 0px #00000014;
  padding: 10px;
  width: 100%;
  margin-bottom: 15px;
`;

export const MatchContainer = styled.div`
  width: 100%;
`;

export const CardRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const CardRowForSvg = styled.div`
  display: flex;
  align-items: center;
`;

export const UserImg = styled.img`
  width: 46px;
  height: 46px;
  border-radius: 23px;
  margin-right: 8px;
  margin-bottom: 4px;
`;

export const MatchName = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.semiBold};
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 14px;
`;

export const MatchJob = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.semiBold};
  color: ${({ theme }) => theme.colors.grayLight};
  font-size: 13px;
`;

export const SeeMoreButton = styled(Button).attrs({
  customStyle: { minWidth: 0, flex: 0 },
})``;

// Icones
export const TrashIcon = styled(IconTrash).attrs({
  width: 20,
  height: 24,
})`
  fill: none;

  transform: scale(1);
  transition: transform 350ms ease;
  @media only screen and (min-width: 1024px) {
    &:hover {
      transform: scale(1.15);
    }
  }
`;

export const EditIcon = styled(IconEdit).attrs({
  width: 24,
  height: 24,
})`
  fill: none;
  margin-right: 20px;

  transform: scale(1);
  transition: transform 350ms ease;
  @media only screen and (min-width: 1024px) {
    &:hover {
      transform: scale(1.15);
    }
  }
`;

export const MatchIcon = styled(IconMatchs).attrs({
  width: 29,
  height: 13,
})`
  fill: none;
  margin-right: 5px;
`;

export const AmountIcon = styled(IconAmount).attrs({
  width: 23,
  height: 23,
})`
  fill: none;
  margin-right: 5px;
`;

export const MoneyIcon = styled(IconMoney).attrs({
  width: 22,
  height: 17,
})`
  fill: none;
  margin-right: 5px;
`;

export const PinIcon = styled(IconPin).attrs({
  width: 16,
  height: 21,
})`
  fill: none;
  margin-right: 5px;
`;

export const MailIcon = styled(IconMail).attrs({
  width: 22,
  height: 17,
})`
  fill: none;
  margin-right: 5px;
`;

export const PhoneIcon = styled(IconPhone).attrs({
  width: 17,
  height: 17,
})`
  fill: none;
  color: ${({ theme }) => theme.colors.textSecondary};
  margin-right: 5px;
`;
export const CpfIcon = styled(IconCpf).attrs({
  width: 12,
  height: 17,
})`
  fill: none;
  margin-right: 5px;
`;

export const UserIcon = styled(IconUser).attrs({
  width: 17,
  height: 17,
})`
  color: ${({ theme }) => theme.colors.textSecondary};
  margin-right: 5px;
`;

// Load
export const LoadRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const LoadingIndicator = styled(Load).attrs(({ theme }) => ({
  size: 'small',
  customStyle: {
    color: theme.colors.secondaryDark
  }
}))`
  height: 40px;
  display: block;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
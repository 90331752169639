import React, { LegacyRef } from 'react';

import typ from './index.d';
import { Modal, Separator } from './styles';
import InputComponent from '../../input';
import { toast } from 'react-toastify';
import InputSelectComponent from '../../input-select';
import {
  CONST_GRADUATION,
} from '../../../utils/CONSTANTS';
import { LanguageCRUD } from '../../../services/requests/language';
import { GraduationCRUD } from '../../../services/requests/graduation';

const graduationCRUD = new GraduationCRUD();

const GraduationModal: React.FC<typ.Props> = ({
  onClose,
  graduation,
  graduationId,
  isVacancy = true
}) => {
  // alert(graduationId)
  // States
  const [institutionName, setInstitutionName] = React.useState(
    graduation?.institution_name || '',
  );
  const [graduationName, setGraduationName] = React.useState(
    graduation?.graduation_name || '',
  );
  const [graduationType, setGraduationType] = React.useState(
    graduation?.graduation_type || '',
  );
  const [graduationTime, setGraduationTime] = React.useState(
    graduation?.graduation_time || '',
  );
  const [description, setDescription] = React.useState(
    graduation?.graduation_description || '',
  );

  const [warning, setWarning] = React.useState({
    institutionName: false,
    graduationName: false,
    graduationType: false,
    graduationTime: false,
    description: false,
  });

  const createGraduation = React.useCallback(
    async (
      name: string,
      graduation: string,
      type: string,
      time: string,
      description: string,
    ) => {
      try {
        if (!name && !graduation && !type && !time && !description) {
          setWarning({
            institutionName: true,
            graduationName: true,
            graduationType: true,
            graduationTime: true,
            description: true,
          });
          toast('Digite todos os campos.');
          return;
        }
        if (!name) {
          setWarning(e => ({ ...e, institutionName: true }));
          toast('Digite o nome.');
          return;
        }
        if (!graduation) {
          setWarning(e => ({ ...e, graduationName: true }));
          toast('Digite o curso.');
          return;
        }
        if (!type) {
          setWarning(e => ({ ...e, graduationType: true }));
          toast('Selecione o tipo da graduação.');
          return;
        }
        if (!time) {
          setWarning(e => ({ ...e, graduationTime: true }));
          toast('Digite o tempo.');
          return;
        }
        if (!description) {
          setWarning(e => ({ ...e, description: true }));
          toast('Digite a descrição.');
          return;
        }

        const { data, status, error } = await graduationCRUD.create({
          graduation_description: description,
          graduation_name: graduation,
          graduation_time: time,
          graduation_type: type,
          institution_name: name,
          id: graduationId || 0,
          isVancacie: isVacancy,
        });

        if (error) throw new Error(error.message.description);

        if (status === 200) {
          toast('Graduação associada com sucesso.');
          onClose();
        }
      } catch (e) {
        alert(e);
      }
    },
    [],
  );

  const editGraduation = React.useCallback(
    async (
      name: string,
      graduation: string,
      type: string,
      time: string,
      description: string,
      id: number,
    ) => {
      try {
        if (!name && !graduation && !type && !time && !description) {
          setWarning({
            institutionName: true,
            graduationName: true,
            graduationType: true,
            graduationTime: true,
            description: true,
          });
          toast('Digite todos os campos.');
          return;
        }
        if (!name) {
          setWarning(e => ({ ...e, institutionName: true }));
          toast('Digite o nome.');
          return;
        }
        if (!graduation) {
          setWarning(e => ({ ...e, graduationName: true }));
          toast('Digite o curso.');
          return;
        }
        if (!type) {
          setWarning(e => ({ ...e, graduationType: true }));
          toast('Selecione o tipo da graduação.');
          return;
        }
        if (!time) {
          setWarning(e => ({ ...e, graduationTime: true }));
          toast('Digite o tempo.');
          return;
        }
        if (!description) {
          setWarning(e => ({ ...e, description: true }));
          toast('Digite a descrição.');
          return;
        }

        let currentGraduation: string;

        if (
          CONST_GRADUATION.findIndex(
            item => item.label.toLowerCase() === type.toLocaleLowerCase(),
          ) > 0
        ) {
          currentGraduation =
            CONST_GRADUATION[
              CONST_GRADUATION.findIndex(
                item => item.label.toLowerCase() === type.toLocaleLowerCase(),
              )
            ].keys;
        } else {
          currentGraduation = type;
        }

        const { data, status, error } = await graduationCRUD.update({
          id,
          graduation_description: description,
          graduation_name: graduation,
          graduation_time: time,
          graduation_type: currentGraduation,
          institution_name: name,
        });

        if (error) throw new Error(error.message.description);

        if (status === 200) {
          toast('Linguagem atualizada com sucesso.');
          onClose();
        }
      } catch (e) {
        alert(e);
      }
    },
    [],
  );

  return (
    <Modal
      modalTitle={`${graduation ? 'Editar' : 'Adicionar'} graduação`}
      onClose={onClose}
      buttonCallback={() => {
        if (graduation) {
          editGraduation(
            institutionName,
            graduationName,
            graduationType,
            graduationTime,
            description,
            graduation.id,
          );
          return;
        }
        createGraduation(
          institutionName,
          graduationName,
          graduationType,
          graduationTime,
          description,
        );
      }}
      buttonTitle={`${graduation ? 'Editar' : 'Enviar'}`}>
      <InputComponent
        placeholder="Nome da instituição"
        type="text"
        value={institutionName}
        setValue={setInstitutionName}
        warning={warning.institutionName}
      />

      <Separator />

      <InputComponent
        customContainerSize="full"
        value={graduationName}
        setValue={setGraduationName}
        placeholder={graduation?.graduation_name || 'Nome do curso'}
        warning={warning.graduationTime}
      />

      <Separator />

      <InputSelectComponent
        customContainerSize="full"
        placeholder={graduation?.graduation_type || 'Tipo da graduação'}
        value={graduationType}
        setValue={setGraduationType}
        customStyle={{ background: '#fff' }}
        warning={warning.graduationType}
        selectData={CONST_GRADUATION}
      />

      <Separator />

      <InputComponent
        customContainerSize="full"
        value={graduationTime}
        setValue={setGraduationTime}
        placeholder={graduation?.graduation_time || 'Tempo de curso'}
        warning={warning.graduationTime}
      />
      <Separator />

      <InputComponent
        customContainerSize="full"
        value={description}
        setValue={setDescription}
        placeholder={graduation?.graduation_description || 'Descrição'}
        warning={warning.graduationTime}
      />
    </Modal>
  );
};

export default GraduationModal;

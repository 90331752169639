import React from 'react';

import typ from './index.d';
import {
  Box,
  Button,
  ButtonsArea,
  CloseArea,
  CloseIcon,
  Container,
  Description,
  Title,
  WarnIcon,
} from './styled';

const WarnModal: React.FC<typ.Props> = ({
  title,
  description,
  showWarnIcon,
  
  confirmCallback,
  confirmButtonTitle, 
  confirmButtonBgColor,
  confirmButtonTextColor,

  declineCallback,
  declineButtonTitle,
  declineButtonBgColor,
  declineButtonTextColor,

  onClose
}) => {
  return (
    <Container>
      {onClose && (
        <CloseArea>
          <CloseIcon onClick={onClose}/>
        </CloseArea>
      )}
      <Box>
        {showWarnIcon && <WarnIcon />}
        <Title>{title}</Title>
        <Description>{description}</Description>
        <ButtonsArea>
          <Button onClick={declineCallback} bg={declineButtonBgColor} textColor={declineButtonTextColor} >{declineButtonTitle}</Button>
          <Button accept onClick={confirmCallback} bg={confirmButtonBgColor} textColor={confirmButtonTextColor}>
           {confirmButtonTitle}
          </Button>
        </ButtonsArea>
      </Box>
    </Container>
  );
};

export default WarnModal;

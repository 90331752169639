import styled from 'styled-components';
import { IconClose, IconWarn } from '../../icons';

export const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 998;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  max-width: 326px;
  padding: 25px;
  z-index: 999
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.text};
  font-family: ${({ theme }) => theme.fontFamily.poppins.semiBold};
  font-size: 24px;
  margin: 0 0 5px;
`;
export const Description = styled.p`
  color: ${({ theme }) => theme.colors.text};
  font-family: ${({ theme }) => theme.fontFamily.poppins.regular};
  font-size: 16px;
  margin: 0 0 35px;
  text-align: center;
`;
export const ButtonsArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Button = styled.div<{ accept?: boolean, bg?: string, textColor?: string }>`
  border-radius: 3px;
  padding: 4px 16px;
  background-color: ${({ theme, accept, bg }) =>
    bg ? bg : accept ? theme.colors.red : theme.colors.redUltraLight};
  font-family: ${({ theme }) => theme.fontFamily.poppins.regular};
  color: ${({ theme, accept, textColor }) => textColor ? textColor : accept ? theme.colors.white: theme.colors.textSecondary};
  cursor: pointer;
  transform: scale(1);
  transition: transform 350ms ease;
  font-size: 14px;
  @media only screen and (min-width: 1024px) {
    &:hover {
      transform: scale(1.25);
    }
  }
`;

export const CloseArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 326px;
  width: 100%;
  margin-bottom: 8px
`;


// Icons
export const WarnIcon = styled(IconWarn).attrs({
  width: 54,
  height: 54,
})`
  margin-bottom: 8px;
`;
export const CloseIcon = styled(IconClose).attrs(({theme}) => ({
  width: 20,
  height: 20,
  color: theme.colors.white
}))`
  cursor: pointer;
  transform: scale(1);
  transition: transform 350ms ease;
  @media only screen and (min-width: 1024px) {
    &:hover {
      transform: scale(1.25);
    }
  }
`;

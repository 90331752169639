import Cookies from 'js-cookie';
import { axiosManager } from '../../axios-manager';
import InterviewCRUDProps from './index.d';
('./index.d');

class InterviewCRUD {
  private readonly token;

  constructor() {
    this.token = Cookies.get('token') || '';
  }
  create({
    advisor_id,
    personal_client_id,
    vacancy_id,
    company,
    status,
    contact
  }: InterviewCRUDProps.createParams) {
    return axiosManager<InterviewCRUDProps.createReturn>({
      method: 'post',
      url: `/interview/create`,
      auth: this.token,
      payload: {
        advisor_id,
        personal_client_id,
        vacancy_id,
        company,
        status,
        contact
      },
    });
  }

  read({
    id,
    page = 1,
    per_page = 10,
  }: InterviewCRUDProps.readParams) {
    let url = `/interview/list?page=${page}&per_page=${per_page}`;

    if (id) url = url + `&personal_client_id=${id}`;

    return axiosManager<InterviewCRUDProps.readReturn>({
      method: 'get',
      url,
      auth: this.token,
      payload: null,
    });
  }

  delete({ id }: InterviewCRUDProps.deleteParams) {
    return axiosManager<InterviewCRUDProps.deleteReturn>({
      method: 'delete',
      url: `/interview/delete/${id}`,
      auth: this.token,
      payload: null,
    });
  }
}

export { InterviewCRUD };

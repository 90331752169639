import React from 'react';
import {
  AddConsultantButton,
  AddIcon,
  BackFullIcon,
  Container,
  Content,
  Header,
  HeaderActions,
  HeaderBack,
  HeaderInfo,
  HeaderTitle,
  LoadRow,
  NextPageButton,
  PaginationArea,
  PreviousPageButton,
  TalentBankContainer,
} from './styles';
import MenuBar from '../../../components/menu-bar';
import ResponsiveContainer from '../../../components/container';
import Search from '../../../components/search';
import CardUserInfo from '../../../components/cards/user-info';
import CandidateModal from '../../../components/modais/applicant';
import WarnModal from '../../../components/modais/warn';
import TalentPoolCRUDProps, {
  TalentPoolData,
} from '../../../services/requests/talent-pool/index.d';
import { TalentPoolCRUD } from '../../../services/requests/talent-pool';
import Load from '../../../components/load';
import { useTheme } from 'styled-components';
import { toast } from 'react-toastify';
import PDFmodal from '../../../components/modais/pdf';
import Cookies from 'js-cookie';
import { CONST_API } from '../../../utils/CONSTANTS';

const talentPoolCRUD = new TalentPoolCRUD();

const TalentPoolPage: React.FC = () => {
  const { colors } = useTheme();

  // Ref
  const currentPage = React.useRef(1);

  // States
  const [search, setSearch] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);
  const [showPDF, setPDFModal] = React.useState(false);
  const [showChoosePDF, setChoosePDF] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [currentTalent, setCurrentTalent] = React.useState<TalentPoolData>();
  const [showWarn, setShowWarn] = React.useState(false);
  const [talentsPool, setTalentsPool] =
    React.useState<TalentPoolCRUDProps.readReturn>();

  // Callbacks
  const getTalentsPools = React.useCallback(async (page?: number) => {
    try {
      setLoading(true);
      currentPage.current = page || 1;
      const { data, status, error } = await talentPoolCRUD.read({
        page,
        per_page: 12,
      });

      if (error) {
        throw new Error(error.message.description);
      }

      if (status === 200 && data) {
        setTalentsPool(data);
      }
    } catch (e) {
      alert(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleDeleteTalentPool = React.useCallback(async (id: number) => {
    try {
      const { data, status, error } = await talentPoolCRUD.delete({ id });

      if (error) throw new Error('Erro ao deletar usuario.');

      if (status === 200) {
        toast('Cliente deletado com sucesso.');
        getTalentsPools();
      }
    } catch (e) {
      toast(e as String);
    }
  }, []);

  const downloadPDF = React.useCallback(async (name: string, id: number) => {
    try {
      const response = await fetch(`${CONST_API.protocol}${CONST_API.baseUrl}/talent-pool/attachment/view/${id}`, {
        method: 'GET',
        headers: {
          Accept: 'application/pdf',
          Authorization: `Bearer ${Cookies.get('token')}`
        }
      })

      if(!response.ok) {
        throw new Error('Nenhum pdf encontrado.')
      }

      const pdf = await response.blob();

      const url = URL.createObjectURL(pdf);
      const link = document.createElement('a');

      link.href = url;
      link.download = `${name}.pdf`; 
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (e) {
      const {message} = e as Error
      toast(message);
    } 
  }, []);

  // Search
  const currentData = React.useMemo(() => {
    if (search) {
      let currentFilter = search.toLocaleLowerCase();
      return talentsPool?.TalentPool?.filter(e =>
        e.name.toLowerCase().includes(currentFilter),
      );
    }
    return talentsPool?.TalentPool;
  }, [talentsPool, search]);

  // Effect
  React.useEffect(() => {
    getTalentsPools();
  }, []);

  return (
    <Container>
      <MenuBar currentPage="Talentos" />
      <Content>
        <ResponsiveContainer>
          <Header>
            <HeaderBack href="/">
              <BackFullIcon />
            </HeaderBack>
            <HeaderInfo>
              <HeaderTitle>Banco de talentos</HeaderTitle>
              <HeaderActions>
                <Search
                  value={search}
                  setValue={setSearch}
                  placeholder="Pesquisar clientes"
                />
                <AddConsultantButton
                  title="Adicionar cliente"
                  iconRight={AddIcon}
                  callback={() => setShowModal(true)}
                />
              </HeaderActions>
            </HeaderInfo>
          </Header>
          <TalentBankContainer>
            {loading ? (
              <LoadRow>
                <Load
                  customStyle={{
                    color: colors.secondaryDark,
                  }}
                  size="extraLarge"
                />
              </LoadRow>
            ) : (
              <>
                {currentData && currentData?.length > 0 ? (
                  currentData?.map((e, i) => {
                    return (
                      <CardUserInfo
                        talentPool={e}
                        key={i}
                        deleteCallback={() => {
                          setCurrentTalent(e);
                          setShowWarn(true);
                        }}
                        editCallback={() => {
                          setCurrentTalent(e);
                          setShowModal(true);
                        }}
                        pdfCallback={() => {
                          setCurrentTalent(e);
                          setChoosePDF(true);
                        }}
                      />
                    );
                  })
                ) : (
                  <HeaderTitle>Nenhum talento encontrado...</HeaderTitle>
                )}
                <PaginationArea>
                  {talentsPool?.has_prev && (
                    <PreviousPageButton
                      title="Voltar Página"
                      callback={() => getTalentsPools(currentPage.current - 1)}
                    />
                  )}
                  {talentsPool?.has_next && (
                    <NextPageButton
                      title="Proxima Página"
                      callback={() => getTalentsPools(currentPage.current + 1)}
                    />
                  )}
                </PaginationArea>
              </>
            )}
          </TalentBankContainer>
        </ResponsiveContainer>
      </Content>

      {showModal && (
        <CandidateModal
          onClose={() => {
            getTalentsPools();
            setShowModal(false);
            setCurrentTalent(undefined);
          }}
          talentPool={currentTalent}
        />
      )}
      
      {showPDF && (
        <PDFmodal
          onClose={() => {
            setPDFModal(false);
            setCurrentTalent(undefined);
          }}
          currentUserId={currentTalent?.id}
          userType='talent-pool'
        />
      )}

      {showChoosePDF && (
        <WarnModal
         title="O que deseja fazer?"
         description={`Deseja visualizar ou baixar o curriculo de ${currentTalent?.name}?`}
          confirmCallback={() => {
           setPDFModal(true)
           setChoosePDF(false)
          }}
          declineCallback={() => {
            downloadPDF(currentTalent?.name || '' , currentTalent?.id || 0 )
          }}
          confirmButtonTitle='Visualizar PDF'
          confirmButtonBgColor={colors.primaryLight}
          declineButtonTitle='Baixar o PDF'
          declineButtonBgColor={colors.secondary}
          declineButtonTextColor={colors.white}
          onClose={() => {
            setChoosePDF(false)
            setCurrentTalent(undefined);
          }}
       />
      )}

      {showWarn && currentTalent && (
        <WarnModal
          title="Remover talento"
          description={`Certeza que quer remover o ${currentTalent.name} do seu banco de talentos?`}
          confirmCallback={() => {
            handleDeleteTalentPool(currentTalent?.id || 0);
            setShowWarn(false);
          }}
          declineCallback={() => {
            setCurrentTalent(undefined);
            setShowWarn(false);
          }}
          confirmButtonTitle='Sim, Remover.'
          declineButtonTitle='Não, Manter.'
        />
      )}

    </Container>
  );
};

export default TalentPoolPage;

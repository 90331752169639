import styled from 'styled-components';
import Button from '../../../../components/button';
import {
  IconAdd,
  IconAmount,
  IconBackFull,
  IconCpf,
  IconEdit,
  IconMatchs,
  IconMoney,
  IconPin,
  IconTrash,
} from '../../../../components/icons';
import Load from '../../../../components/load';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px;
  height: 100vh;
  /* background-color: ${({ theme }) => theme.colors.grayUltraHigh} */
  background-color: #f9f9f9;
`;

export const Responsive = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  @media only screen and (min-width: 768px) {
    max-width: 750px;
  }
  @media only screen and (min-width: 991px) {
    max-width: 970px;
  }
  @media only screen and (min-width: 1200px) {
    max-width: 1170px;
  }
  @media only screen and (min-width: 1600px) {
    max-width: 1630px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow-x: scroll;
`;

export const Header = styled.div`
  padding: 25px 0px 0px;
  margin-bottom: 34px;
`;

export const HeaderBack = styled.a`
  cursor: pointer;
`;

export const HeaderInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

export const HeaderTitle = styled.h2`
  color: ${({ theme }) => theme.colors.textSecondary};
  font-family: ${({ theme }) => theme.fontFamily.inter.semiBold};
  font-size: 20px;
  margin: 0px;
`;

export const ContainerCards = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const CardsColumns = styled.div<{
  maxWidth?: number;
  minWidth?: number;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0 25px 0 0;
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : `356px`)};
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : `356px`)};
`;

export const Card = styled.div<{ alternative?: boolean; maxHeight?: number }>`
  background-color: ${({ theme, alternative }) =>
    alternative ? '#E5E7EA' : theme.colors.white};
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  margin: 0 0 25px 0;
  max-height: ${({ maxHeight }) => (maxHeight && `${maxHeight}px`)};
  overflow-y: ${({ maxHeight }) => maxHeight && 'scroll'};
`;

export const CardSectionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;
export const CardSectionColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const ButtonComp = styled.button``;

export const CardSectionTitle = styled.h3<{ size: 'huge' | 'normal' }>`
  margin: 0px 0px;
  font-size: ${({ size }) => (size === 'normal' ? '18px' : '22px')};
  color: ${({ theme }) => theme.colors.textSecondary};
  font-family: ${({ theme }) => theme.fontFamily.inter.semiBold};
`;

export const CardSeparator = styled.div`
  margin: 25px 0 20px;
  height: 1px;
  background-color: #dcdcdc;
  width: 100%;
`;

export const CardDescriptionTitle = styled.label`
  display: block;
  margin: 0px 0px 5px;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.textSecondary};
  font-family: ${({ theme }) => theme.fontFamily.inter.semiBold};
`;

export const CardDescriptionLabel = styled.label`
  display: block;
  margin: 0px 0px 30px;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.grayLight};
  font-family: ${({ theme }) => theme.fontFamily.inter.semiBold};
`;

export const Name = styled.h3`
  margin: 0px 0px 6px;
  font-size: 36px;
  color: ${({ theme }) => theme.colors.secondaryDark};
  font-family: ${({ theme }) => theme.fontFamily.inter.semiBold};
  line-height: 36.57px;
`;

export const Occupation = styled.h3`
  margin: 0px 0px 6px;
  font-size: 20px;
  color: #959595;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
`;

export const KeyWordsArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin: 18px 0 0;
`;

export const KeyWord = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  color: #3a3577;
  font-size: 15px;
  background-color: #dfddff;
  border: 1px solid #8075ff;
  border-radius: 4px;
  padding: 3px 9px;
  margin: 0 10px 10px 0;
`;

export const LanguageRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;
export const Language = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.semiBold};
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 16px;
`;

export const GraduationCard = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 12px;
`;

export const GraduationRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const GraduationIcons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const GraduationBold = styled.p`
  margin: 0px 0px 0px;
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 15px;
  font-family: ${({ theme }) => theme.fontFamily.inter.semiBold};
  width: 100%;
`;
export const GraduationText = styled.p`
  margin: 15px 0px 0px;
  color: ${({ theme }) => theme.colors.grayLight};
  font-size: 15px;
  line-height: 18.15px;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
`;

export const LoadingIndicator = styled(Load)`
  height: 40px;
  display: block;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SvgRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MatchCard = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  box-shadow: 0px 8px 24px 0px #00000014;
  padding: 10px;
  width: 100%;
  margin-bottom: 15px;
`;

export const CardRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const CardRowForSvg = styled.div`
  display: flex;
  align-items: center;
`;

export const UserImg = styled.img`
  width: 46px;
  height: 46px;
  border-radius: 23px;
  margin-right: 8px;
  margin-bottom: 4px;
`;

export const MatchName = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.semiBold};
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 14px;
`;
export const MatchJob = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.semiBold};
  color: ${({ theme }) => theme.colors.grayLight};
  font-size: 13px;
`;

export const ConsultantImg = styled.img`
  width: 33px;
  height: 33px;
  border-radius: 25px;
`;

// Icons
export const AddIcon = styled(IconAdd).attrs({
  width: 24,
  height: 24,
})`
  fill: none;
  margin-right: 10px;
`;
export const BackFullIcon = styled(IconBackFull).attrs({
  width: 74,
  height: 22,
})`
  color: #8f8f8f;
  fill: none;
  transition: color 350ms ease;
  @media only screen and (min-width: 1024px) {
    &:hover {
      color: ${({ theme }) => theme.colors.secondaryDark};
    }
  }
`;
export const MatchIcon = styled(IconMatchs).attrs({
  width: 29,
  height: 13,
})`
  fill: none;
  color: ${({ theme }) => theme.colors.textSecondary};
  margin-right: 5px;
`;
export const AmountIcon = styled(IconAmount).attrs({
  width: 23,
  height: 23,
})`
  fill: none;
  margin-right: 5px;
`;
export const MoneyIcon = styled(IconMoney).attrs({
  width: 22,
  height: 17,
})`
  fill: none;
  margin-right: 5px;
`;
export const PinIcon = styled(IconPin).attrs({
  width: 11,
  height: 15,
})`
  fill: none;
  margin-right: 5px;
`;
export const EditIcon = styled(IconEdit).attrs({
  width: 24,
  height: 24,
})`
  fill: none;

  transform: scale(1);
  transition: transform 350ms ease;
  @media only screen and (min-width: 1024px) {
    &:hover {
      transform: scale(1.15);
    }
  }
`;
export const TrashIcon = styled(IconTrash).attrs({
  width: 16,
  height: 20,
})`
  fill: none;

  transform: scale(1);
  transition: transform 350ms ease;
  @media only screen and (min-width: 1024px) {
    &:hover {
      transform: scale(1.15);
    }
  }
`;
export const CpfIcon = styled(IconCpf).attrs({
  width: 12,
  height: 17,
})`
  fill: none;
  margin-right: 5px;
`;

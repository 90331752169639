import React from 'react';

import typ from './index.d';
import { Modal, Separator } from './styles';
import InputComponent from '../../input';
import { toast } from 'react-toastify';
import InputSelectComponent from '../../input-select';
import { InterviewCRUD } from '../../../services/requests/interview';
import { useAppSelector } from '../../../hooks/useRedux';
import { VancancieCRUD } from '../../../services/requests/vancacies';
import { CONST_GRADUATION } from '../../../utils/CONSTANTS';

const interviewCRUD = new InterviewCRUD();
const vacancyCRUD = new VancancieCRUD()

const InterviewModal: React.FC<typ.Props> = ({
  onClose,
  personalClientId
}) => {

  // Redux
  const advisorId = useAppSelector(({ state }) => state.user?.id)
  
  
  // States
  const [company, setCompany] = React.useState('');
  const [statusInput, setStatusInput] = React.useState('');
  const [contact, setContact] = React.useState('');
  const [vacancyId, setVacancyId] = React.useState('')
  const [currentVacancys, setCurrentVacancys] = React.useState<{keys: string, label: string}[]>()
  const [warning, setWarning] = React.useState({
    company: false,
    status: false,
    contact: false,
    vacancyId: false,
  });


  const createInterview = React.useCallback(
    async (
      company: string,
      status: string,
      contact: string,
      vacancyId: string,
    ) => {
      try {
        if (!company && !status && !contact && !vacancyId) {
          setWarning({
            company: true,
            status: true,
            contact: true,
            vacancyId: true,
          });
          toast('Digite todos os campos.');
          return;
        }
        if (!company) {
          setWarning(e => ({ ...e, company: true }));
          toast('Digite a empresa.');
          return;
        }
        if (!status) {
          setWarning(e => ({ ...e, status: true }));
          toast('Digite o status atual.');
          return;
        }
        if (!contact) {
          setWarning(e => ({ ...e, contact: true }));
          toast('Digite o contato');
          return;
        }
        if (!vacancyId) {
          setWarning(e => ({ ...e, vacancyId: true }));
          toast('Selecione a vaga relacionada.');
          return;
        }

        const { data, status: statusReq, error } = await interviewCRUD.create({
          vacancy_id: Number(vacancyId),
          advisor_id: Number(advisorId),
          personal_client_id: personalClientId,
          company,
          status,
          contact,
        });

        if (error) throw new Error(error.message.description);

        if (statusReq === 200) {
          toast('Entrevista associada com sucesso.');
          onClose();
        }
      } catch (e) {
        alert(e);
      }
    },
    [],
  );

  const getVacancys = React.useCallback( async () => {
      try {
        const { data, status, error } = await vacancyCRUD.read({per_page: 100});

        if (error) throw new Error(error.message.description);

        if (status === 200) {
          const newArray = data.Vacancy.map(e => ({keys: e.id.toString(), label: e.name}))
          setCurrentVacancys(newArray);
        }
      } catch (e) {
        alert(e);
      }
    },
    [],
  );

  React.useEffect(() => {
    getVacancys()
  }, [])

  return (
    <Modal
      modalTitle={`Adicionar entrevista`}
      onClose={onClose}
      buttonCallback={() => {
        createInterview(
          company,
          statusInput,
          contact,
          vacancyId
        );
      }}
      buttonTitle={`Adicionar`}>
      <InputComponent
        placeholder="Nome da empresa"
        type="text"
        value={company}
        setValue={setCompany}
        warning={warning.company}
      />

      <Separator />

      <InputComponent
        customContainerSize="full"
        value={statusInput}
        setValue={setStatusInput}
        placeholder={'Status da entrevista'}
        warning={warning.status}
      />

      <Separator />

      <InputComponent
        customContainerSize="full"
        value={contact}
        setValue={setContact}
        warning={warning.contact}
        placeholder={'Contato da entrevista'}
      />

      <Separator />

      <InputSelectComponent
        customContainerSize="full"
        placeholder={'Vaga da entrevista'}
        value={vacancyId}
        setValue={setVacancyId}
        customStyle={{ background: '#fff' }}
        warning={warning.vacancyId}
        selectData={currentVacancys}
      />
    </Modal>
  );
};

export default InterviewModal;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
  height: 100vh;
  @media only screen and (min-width: 480px) {
    padding: 40px;
  }
  @media only screen and (min-width: 991px) {
    justify-content: flex-start;
  }
  @media only screen and (min-width: 1200px) {
    padding: 60px 85px 60px 60px;
  }
  @media only screen and (min-width: 1350px) {
    padding: 60px 200px 60px 60px;
  }
  @media only screen and (min-width: 1600px) {
    padding: 60px 300px 60px 60px;
  }
`;

export const DescriptionArea = styled.div`
  display: none;
  @media only screen and (min-width: 991px) {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
  }
  @media only screen and (min-width: 1600px) {
    margin-right: 50px;
  }
`;

export const Title = styled.h1`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.text};
  font-family: ${({ theme }) => theme.fontFamily.poppins.semiBold};

  @media only screen and (min-width: 480px) {
    font-size: 28px;
  }
  @media only screen and (min-width: 768px) {
    font-size: 32px;
  }
  @media only screen and (min-width: 991px) {
    font-size: 34px;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 42px;
  }
  @media only screen and (min-width: 1600px) {
    font-size: 49px;
  }
`;
export const Description = styled.h3`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.text};
  font-family: ${({ theme }) => theme.fontFamily.poppins.semiBold};

  @media only screen and (min-width: 480px) {
    font-size: 22px;
  }
  @media only screen and (min-width: 768px) {
    font-size: 25px;
  }
  @media only screen and (min-width: 991px) {
    font-size: 28px;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 32px;
  }
  @media only screen and (min-width: 1600px) {
    font-size: 40px;
  }
`;

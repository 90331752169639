import React from 'react';
import {
  AddConsultantButton,
  AddIcon,
  BackFullIcon,
  Card,
  CardButton,
  CardContent,
  CardContentColumn,
  CardEmail,
  CardInfos,
  CardsContainer,
  CardSvgRow,
  CardTitle,
  Container,
  Content,
  EditIcon,
  Header,
  HeaderActions,
  HeaderBack,
  HeaderInfo,
  HeaderTitle,
  ListIcon,
  LoadRow,
  MalletIcon,
  NextPageButton,
  PaginationArea,
  PhoneIcon,
  PreviousPageButton,
  TrashIcon,
} from './styles';
import MenuBar from '../../../components/menu-bar';
import ResponsiveContainer from '../../../components/container';
import Search from '../../../components/search';
import ProspectModal from '../../../components/modais/prospect';
import Load from '../../../components/load';
import { useTheme } from 'styled-components';
import ProspectClientCRUDProps, {
  ProspectClientData,
} from '../../../services/requests/prospect-client/index.d';
import { ProspectClientCRUD } from '../../../services/requests/prospect-client';
import { formatarPhone } from '../../../utils/convert';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import WarnModal from '../../../components/modais/warn';

const prospectCrud = new ProspectClientCRUD();

const ProspectClientsPage: React.FC = () => {
  const { colors } = useTheme();
  const navigate = useNavigate();

  // Ref
  const currentPage = React.useRef(1);

  // States
  const [search, setSearch] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);
  const [showWarn, setShowWarn] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [prospects, setProspects] =
    React.useState<ProspectClientCRUDProps.readReturn>();
  const [currentProspect, setCurrentProspect] =
    React.useState<ProspectClientData>();

  // Callbacks
  const refreshData = React.useCallback(async (page?: number) => {
    try {
      currentPage.current = page || 1;
      const { data, status, error } = await prospectCrud.read({
        page,
        per_page: 12,
      });

      if (error) {
        throw new Error(error.message.description);
      }

      if (status === 200 && data) {
        setProspects(data);
      }
    } catch (e) {
      alert(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleDeleteProspect = React.useCallback(async (id: number) => {
    try {
      const { data, status, error } = await prospectCrud.delete({
        id: id,
      });

      if (error) throw new Error(error.message.description);

      if (status === 200) {
        refreshData();
        toast('Cliente deletado com sucesso.');
      }
    } catch (e) {
      alert(e);
    }
  }, []);

  const handleCloseModal = React.useCallback(() => {
    setCurrentProspect(undefined);
    setShowModal(false);
  }, []);

  const currentData = React.useMemo(() => {
    if (search) {
      let currentFilter = search.toLocaleLowerCase();
      return prospects?.ProspectClient?.filter(e =>
        e.name.toLowerCase().includes(currentFilter),
      );
    }
    return prospects?.ProspectClient;
  }, [prospects, search]);

  React.useEffect(() => {
    refreshData();
  }, []);

  return (
    <Container>
      <MenuBar currentPage="Prospecção" />
      <Content>
        <ResponsiveContainer>
          <Header>
            <HeaderBack href="/">
              <BackFullIcon />
            </HeaderBack>
            <HeaderInfo>
              <HeaderTitle>Todos os clientes de prospecção</HeaderTitle>
              <HeaderActions>
                <Search
                  value={search}
                  setValue={setSearch}
                  placeholder="Pesquisar clientes"
                />
                <AddConsultantButton
                  title="Adicionar cliente"
                  iconRight={AddIcon}
                  callback={() => setShowModal(true)}
                />
              </HeaderActions>
            </HeaderInfo>
          </Header>
          <CardsContainer>
            {loading ? (
              <LoadRow>
                <Load
                  customStyle={{
                    color: colors.secondaryDark,
                  }}
                  size="extraLarge"
                />
              </LoadRow>
            ) : (
              <>
                {currentData && currentData?.length > 0 ? (
                  currentData?.map((e, i) => {
                    return (
                      <Card>
                        <CardContent
                          onClick={() =>
                            navigate(`/historico-contato/${e.id}`, {
                              state: { name: e.name },
                            })
                          }>
                          <CardTitle>{e.name}</CardTitle>

                          <CardEmail>{e.email}</CardEmail>
                          <CardSvgRow style={{ marginBottom: 4 }}>
                            <PhoneIcon />
                            <CardInfos>{formatarPhone(e.phone)}</CardInfos>
                          </CardSvgRow>
                          <CardSvgRow>
                            <MalletIcon />
                            <CardInfos>{e.company_type}</CardInfos>
                          </CardSvgRow>

                          <CardSvgRow style={{ marginTop: 20 }}>
                            <ListIcon />
                            <CardInfos>{e.contact_history_quantity}</CardInfos>
                          </CardSvgRow>
                        </CardContent>

                        <CardContentColumn>
                          <CardButton
                            onClick={() => {
                              setCurrentProspect(e), setShowModal(true);
                            }}>
                            <EditIcon />
                          </CardButton>
                          <CardButton
                            onClick={() => {
                              setCurrentProspect(e);
                              setShowWarn(true);
                            }}>
                            <TrashIcon />
                          </CardButton>
                        </CardContentColumn>
                      </Card>
                    );
                  })
                ) : (
                  <HeaderTitle>Nenhum cliente encontrado...</HeaderTitle>
                )}
                <PaginationArea>
                  {currentData &&
                    currentData?.length > 12 &&
                    prospects?.has_prev && (
                      <PreviousPageButton
                        title="Voltar Página"
                        callback={() => refreshData(currentPage.current - 1)}
                      />
                    )}
                  {currentData &&
                    currentData?.length > 12 &&
                    prospects?.has_next && (
                      <NextPageButton
                        title="Proxima Página"
                        callback={() => refreshData(currentPage.current + 1)}
                      />
                    )}
                </PaginationArea>
              </>
            )}
          </CardsContainer>
        </ResponsiveContainer>
      </Content>
      {showModal && (
        <ProspectModal
          onClose={() => {
            refreshData();
            setCurrentProspect(undefined);
            handleCloseModal();
          }}
          prospect={currentProspect}
        />
      )}
      {showWarn && currentProspect && (
        <WarnModal
          title="Remover cliente"
          description={`Certeza que quer remover o cliente ${currentProspect.name}?`}
          confirmCallback={() => {
            handleDeleteProspect(currentProspect?.id || 0);
            setShowWarn(false);
          }}
          declineCallback={() => {
            setCurrentProspect(undefined);
            setShowWarn(false);
          }}
          confirmButtonTitle='Sim, Remover.'
          declineButtonTitle='Não, Manter.'
        />
      )}
    </Container>
  );
};

export default ProspectClientsPage;

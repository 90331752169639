import React from 'react';
import {
  Modal,
  PDFContent,
} from './styles';
import { Viewer, Worker} from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import typ from './index.d';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { CONST_API } from '../../../utils/CONSTANTS';


const PDFmodal: React.FC<typ.Props> = ({
  onClose,
  currentUserId, 
  userType
}) => {

  // Ref
  const currentLifeCicle = React.useRef(false);

  // States
  const [pdf, setPdf] = React.useState<any>()

  const getPdf = React.useCallback(
    async () => {
      if(currentLifeCicle.current) return;
      currentLifeCicle.current = true;

      try {
        const response = await fetch(`${CONST_API.protocol}${CONST_API.baseUrl}${userType}/attachment/view/${currentUserId}`, {
          method: 'GET',
          headers: {
            Accept: 'application/pdf',
            Authorization: `Bearer ${Cookies.get('token')}`
          }
        })

        if(!response.ok) {
          throw new Error('Nenhum pdf encontrado.')
        }
        const pdf = await response.blob();

        const url = URL.createObjectURL(pdf);
        setPdf(url);
      }  catch(e) {
        const {message} = e as Error
        toast(message);
      } finally {
        if(!currentLifeCicle.current) currentLifeCicle.current = false
      }
      
    }, []
  );

  React.useEffect(() => {
    getPdf()
  }, [])
  
  return (
    <Modal
      modalTitle={`Visualizar curriculo`}
      onClose={onClose}
      hideButton
      maxWidth={1350}
      customStyle={{overflow: 'hidden'}}
      >
        {pdf && (
          <PDFContent>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <Viewer fileUrl={pdf} defaultScale={1.7} />
            </Worker>
          </PDFContent>
        )}
    </Modal>
  );
};

export default PDFmodal;

import React from 'react';
import {
  AddConsultantButton,
  AddIcon,
  BackFullIcon,
  Container,
  Content,
  Header,
  HeaderActions,
  HeaderBack,
  HeaderInfo,
  HeaderTitle,
  RecrutamentContainer,
  RecrutamentCard,
  RecrutamentDeleteButton,
  RecrutamentDescriptionRow,
  RecrutamentDescriptionText,
  TrashIcon,
  EditIcon,
  OptionsIcon,
  LoadRow,
  PaginationArea,
  PreviousPageButton,
  NextPageButton,
} from './styles';
import MenuBar from '../../../components/menu-bar';
import ResponsiveContainer from '../../../components/container';
import Search from '../../../components/search';
import RecrutamentManageModal from '../../../components/modais/recrutament/manage';
import RecrutamentViewModal from '../../../components/modais/recrutament/view';
import { useNavigate } from 'react-router-dom';
import { CorporateClientCRUD } from '../../../services/requests/corporate-client';
import CorporateClientCRUDProps, {
  CorporateClientData,
} from '../../../services/requests/corporate-client/index.d';
import Load from '../../../components/load';
import { useTheme } from 'styled-components';
import WarnModal from '../../../components/modais/warn';
import { toast } from 'react-toastify';

const corporateClientCRUD = new CorporateClientCRUD();

const RecrutamentPage: React.FC = () => {
  const navigation = useNavigate();
  const { colors } = useTheme();

  // Ref
  const currentPage = React.useRef(1);

  // States
  const [search, setSearch] = React.useState('');
  const [clients, setClients] =
    React.useState<CorporateClientCRUDProps.readReturn>();
  const [currentClient, setCurrentClient] =
    React.useState<CorporateClientData>();
  const [showWarn, setShowWarn] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [showViewModal, setShowViewModal] = React.useState(false);
  const [showManagewModal, setShowManageModal] = React.useState(false);

  // Callbacks
  const refreshData = React.useCallback(async (page?: number) => {
    try {
      setLoading(true);
      const { data, status, error } = await corporateClientCRUD.read({
        page,
        per_page: 8,
      });

      if (error) {
        throw new Error(error.message.description);
      }

      if (status === 200 && data) {
        setClients(data);
      }
    } catch (e) {
      alert(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleDelete = React.useCallback(async (id: number) => {
    try {
      const { data, status, error } = await corporateClientCRUD.delete({ id });

      if (error) {
        throw new Error(error.message.description);
      }

      if (status === 200 && data) {
        toast('Cliente removido com sucesso.');
        setShowWarn(false);
        setCurrentClient(undefined);
        refreshData();
      }
    } catch (e) {
      alert(e);
    }
  }, []);

  const currentData = React.useMemo(() => {
    if (search) {
      let currentFilter = search.toLocaleLowerCase();
      return clients?.CorporateClient.filter(e =>
        e.name.toLowerCase().includes(currentFilter),
      );
    }
    return clients?.CorporateClient;
  }, [clients, search]);

  React.useEffect(() => {
    refreshData();
  }, []);

  return (
    <Container>
      <MenuBar currentPage="Recrutamento" />
      <Content>
        <ResponsiveContainer>
          <Header>
            <HeaderBack href="/">
              <BackFullIcon />
            </HeaderBack>
            <HeaderInfo>
              <HeaderTitle>Todos os clientes</HeaderTitle>
              <HeaderActions>
                <Search
                  value={search}
                  setValue={setSearch}
                  placeholder="Pesquisar clientes"
                />
                <AddConsultantButton
                  title="Adicionar cliente"
                  iconRight={AddIcon}
                  callback={() => setShowManageModal(true)}
                />
              </HeaderActions>
            </HeaderInfo>
          </Header>
          <RecrutamentContainer>
            <RecrutamentDescriptionRow>
              <RecrutamentDescriptionText style={{ flex: 1.2 }}>
                Cliente
              </RecrutamentDescriptionText>
              <RecrutamentDescriptionText style={{ flex: 2 }}>
                Vaga
              </RecrutamentDescriptionText>
              <RecrutamentDescriptionText style={{ flex: 2 }}>
                Email
              </RecrutamentDescriptionText>
              <RecrutamentDescriptionText style={{ flex: 2 }}>
                Cnpj
              </RecrutamentDescriptionText>
              <RecrutamentDescriptionText style={{ flex: 0 }}>
                Ação
              </RecrutamentDescriptionText>
            </RecrutamentDescriptionRow>
            {loading ? (
              <LoadRow>
                <Load
                  customStyle={{
                    color: colors.secondaryDark,
                  }}
                  size="extraLarge"
                />
              </LoadRow>
            ) : (
              <>
                {currentData && currentData?.length > 0 ? (
                  currentData?.map((e, i) => {
                    return (
                      <RecrutamentCard key={i}>
                        <RecrutamentDescriptionText
                          isClick
                          style={{ flex: 1.5 }}
                          onClick={() => navigation(`/recrutamento-kambam/${e.id}`,{
                            state: { name: e.name },
                          })}>
                          {e.name}
                        </RecrutamentDescriptionText>
                        <RecrutamentDescriptionText style={{ flex: 2 }}>
                          {e.vacancy_relation?.name}
                        </RecrutamentDescriptionText>
                        <RecrutamentDescriptionText style={{ flex: 2 }}>
                          {e.email}
                        </RecrutamentDescriptionText>
                        <RecrutamentDescriptionText style={{ flex: 2 }}>
                          {formatCNPJ(e.cnpj)}
                        </RecrutamentDescriptionText>
                        <RecrutamentDeleteButton
                          style={{ flex: 0 }}
                          onClick={() => {
                            setCurrentClient(e);
                            setShowManageModal(true);
                          }}>
                          <EditIcon />
                        </RecrutamentDeleteButton>
                        <RecrutamentDeleteButton
                          style={{ flex: 0 }}
                          onClick={() => {
                            setCurrentClient(e);
                            setShowWarn(true);
                          }}>
                          <TrashIcon />
                        </RecrutamentDeleteButton>
                        <RecrutamentDeleteButton
                          style={{ flex: 0 }}
                          onClick={() => {
                            setCurrentClient(e);
                            setShowViewModal(true);
                          }}>
                          <OptionsIcon />
                        </RecrutamentDeleteButton>
                      </RecrutamentCard>
                    );
                  })
                ) : (
                  <HeaderTitle>Nenhum cliente encontrado...</HeaderTitle>
                )}
                <PaginationArea>
                  {clients?.has_prev && (
                    <PreviousPageButton
                      title="Voltar Página"
                      callback={() => refreshData(currentPage.current - 1)}
                    />
                  )}
                  {clients?.has_next && (
                    <NextPageButton
                      title="Proxima Página"
                      callback={() => refreshData(currentPage.current + 1)}
                    />
                  )}
                </PaginationArea>
              </>
            )}
          </RecrutamentContainer>
        </ResponsiveContainer>
      </Content>
      {showManagewModal && (
        <RecrutamentManageModal
          refreshData={refreshData}
          onClose={() => {
            setShowManageModal(false);
            setCurrentClient(undefined);
          }}
          client={currentClient}
        />
      )}
      {showViewModal && (
        <RecrutamentViewModal
          onClose={() => {
            setShowViewModal(false);
            setCurrentClient(undefined);
          }}
          client={currentClient}
          showDeleteModal={() => setShowWarn(true)}
          showEditModal={() => {
            setShowViewModal(false);
            setShowManageModal(true);
          }}
        />
      )}
      {showWarn && currentClient && (
        <WarnModal
          title="Remover Cliente"
          description={`Certeza que quer remover o cliente ${currentClient.name}?`}
          confirmButtonTitle='Sim, remover.'
          confirmCallback={() => {
            handleDelete(currentClient?.id || 0);
            setShowViewModal(false);
            setCurrentClient(undefined);
          }}
          declineButtonTitle='Não, manter.'
          declineCallback={() => {
            setCurrentClient(undefined);
            setShowViewModal(false);
            setShowWarn(false);
          }}
        />
      )}
    </Container>
  );
};

export default RecrutamentPage;

const formatCNPJ = (value: string) => {
  if (value.length > 18) return; // CNPJ formatado: 00.000.000/0000-00
  value = value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
  value = value.replace(/(\d{2})(\d)/, '$1.$2'); // Adiciona o primeiro ponto
  value = value.replace(/(\d{3})(\d)/, '$1.$2'); // Adiciona o segundo ponto
  value = value.replace(/(\d{3})(\d)/, '$1/$2'); // Adiciona a barra
  value = value.replace(/(\d{4})(\d{1,2})$/, '$1-$2'); // Adiciona o hífen antes dos últimos dois dígitos
  return value;
};

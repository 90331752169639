import styled from 'styled-components';
import { IconSearch } from '../icons';

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  /* width: 100%; */
  width: 390px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
`;

export const SearchIcon = styled(IconSearch).attrs({
  width: 18,
  height: 18,
})`
  fill: none;
  margin-right: 10px;
`;
export const Input = styled.input`
  color: ${({ theme }) => theme.colors.grayLight};
  font-family: ${({ theme }) => theme.fontFamily.inter.medium};
  font-size: 16px;
  line-height: 24px;
`;

export const LoadArea = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
`
import React from 'react';
import {
  AddIcon,
  BackFullIcon,
  ButtonAddKeyWord,
  ButtonAddVancacie,
  ButtonRow,
  Container,
  Content,
  Header,
  HeaderBack,
  HeaderInfo,
  HeaderTitle,
  InputContainer,
  InputsContactRow,
  InputsVancacie,
  InputsVancacieRow,
  KeyWord,
  KeyWordsArea,
  LoadRow,
  SectionTitle,
  VacanciesContainer,
} from './styles';
import MenuBar from '../../../../components/menu-bar';
import ResponsiveContainer from '../../../../components/container';
import { VancancieCRUD } from '../../../../services/requests/vancacies';
import InputSelectComponent from '../../../../components/input-select';
import {
  CONST_LEVEL,
  CONST_OCCUPATION,
  CONST_PRIORITYS,
  CONST_SEXUALIDADE,
  CONST_VACANCIETYPE,
} from '../../../../utils/CONSTANTS';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { VancacieData } from '../../../../services/requests/vancacies/index.d';
import { formatarPhone } from '../../../../utils/convert';
import Load from '../../../../components/load';
import { theme } from '../../../../global/styles';

const vancacieCrud = new VancancieCRUD();

const EditVancacie: React.FC = () => {
  const { id } = useParams();

  // States
  const [vancacie, setVancacie] = React.useState<VancacieData>();
  const [loading, setLoading] = React.useState(true);
  
  const [name, setName] = React.useState('');
  const [quantity, setQuantity] = React.useState('');
  const [level, setLevel] = React.useState('');
  const [occupation, setOccupation] = React.useState('');
  const [budget, setBudget] = React.useState('');
  const [sex, setSex] = React.useState('');
  const [priority, setPriority] = React.useState('');
  const [type, setType] = React.useState('');
  const [benefits, setBenefits] = React.useState('');
  const [perfil, setPerfil] = React.useState('');
  const [workplace, setWorkplace] = React.useState('');
  const [keyWord, setKeyWord] = React.useState('');
  const [keyWordList, setKeyWordList] = React.useState<string[]>();

  const [nameContact, setNameContact] = React.useState('');
  const [mailContact, setMailContact] = React.useState('');
  const [phoneContact, setPhoneContact] = React.useState('');
  const [occupationContact, setOccupationContact] = React.useState('');

  // Obrigatorios
  const [warning, setWarning] = React.useState({
    name: false,
    quantity: false,
    level: false,
    occupation: false,
    sex: false,
    type: false,
  });

  // Callbacks
  const handleUpdateVancancie = React.useCallback(
    async (
      name: string,
      quantity: string,
      level: string,
      occupation: string,
      budget: string,
      sex: string,
      priority: string,
      type: string,
      benefits: string,
      perfil: string,
      workplace: string,
      keyWordList: string[] | undefined,
      nameContact: string,
      mailContact: string,
      phoneContact: string,
      occupationContact: string,
    ) => {
      if (
        !name &&
        !quantity &&
        !level &&
        !occupation &&
        !sex &&
        !type
      ) {
        setWarning({
          name: true,
          quantity: true,
          level: true,
          occupation: true,
          sex: true,
          type: true,
        });
        toast('Nenhum campo foi preenchido');
        return;
      }
      if (!name) {
        setWarning(e => ({ ...e, name: true }));
        toast('Preencha o campo indicado.');
        return;
      }
      if (!quantity) {
        setWarning(e => ({ ...e, quantity: true }));
        toast('Preencha o campo indicado.');
        return;
      }
      if (!level) {
        setWarning(e => ({ ...e, level: true }));
        toast('Preencha o campo indicado.');
        return;
      }
      if (!occupation) {
        setWarning(e => ({ ...e, occupation: true }));
        toast('Preencha o campo indicado.');
        return;
      }
      if (!sex) {
        setWarning(e => ({ ...e, sex: true }));
        toast('Preencha o campo indicado.');
        return;
      }
      if (!type) {
        setWarning(e => ({ ...e, type: true }));
        toast('Preencha o campo indicado.');
        return;
      }

      try {
        let currentLevel: string;
        let currentOccupation: string;
        let currentSex: string;
        let currentType: string;

        if (
          CONST_LEVEL.findIndex(
            item => item.label.toLowerCase() === level.toLocaleLowerCase(),
          ) >= 0
        ) {
          currentLevel =
            CONST_LEVEL[
              CONST_LEVEL.findIndex(
                item => item.label.toLowerCase() === level.toLocaleLowerCase(),
              )
            ].keys;
        } else {
          currentLevel = level;
        }

        if (
          CONST_OCCUPATION.findIndex(
            item => item.label.toLowerCase() === occupation.toLocaleLowerCase(),
          ) >= 0
        ) {
          currentOccupation =
            CONST_OCCUPATION[
              CONST_OCCUPATION.findIndex(
                item =>
                  item.label.toLowerCase() === occupation.toLocaleLowerCase(),
              )
            ].keys;
        } else {
          currentOccupation = occupation;
        }
        if (
          CONST_SEXUALIDADE.findIndex(
            item => item.label.toLowerCase() === sex.toLocaleLowerCase(),
          ) >= 0
        ) {
          currentSex =
            CONST_SEXUALIDADE[
              CONST_SEXUALIDADE.findIndex(
                item => item.label.toLowerCase() === sex.toLocaleLowerCase(),
              )
            ].keys;
        } else {
          currentSex = sex;
        }
        if (
          CONST_VACANCIETYPE.findIndex(
            item => item.label.toLowerCase() === sex.toLocaleLowerCase(),
          ) >= 0
        ) {
          currentType =
            CONST_VACANCIETYPE[
              CONST_VACANCIETYPE.findIndex(
                item => item.label.toLowerCase() === type.toLocaleLowerCase(),
              )
            ].keys;
        } else {
          currentType = type;
        }

        const { data, status, error } = await vancacieCrud.update({
          name,
          quantity: parseInt(quantity),
          level: currentLevel,
          occupation: currentOccupation,
          budget,
          sexual_gender: currentSex,
          contact_name: nameContact,
          contact_phone: phoneContact.replace(/\D/g, ''),
          contact_role: occupationContact,
          email: mailContact,
          employment_type: currentType,
          benefits,
          perfil,
          workplace,
          key_words: keyWordList || ['gestao'],
          priority,
          id: Number(id),
        });

        if (error) throw new Error(error.message.description);
        if (status === 200 && data) {
          toast('Vaga editada com sucesso.');
        }
      } catch (e) {
        alert(e);
      }
    },
    [],
  );

  const getCurrentVancacie = React.useCallback(async (page?: number) => {
    try {
      setLoading(true);
      const { data, status, error } = await vancacieCrud.read({
        id: id,
      });

      if (error) {
        throw new Error(error.message.description);
      }

      if (status === 200 && data) {
        const item = data.Vacancy[0];
        setName(item.name);
        setBenefits(item.benefits);
        setBudget(item.budget);
        setKeyWordList(item.key_words);
        setLevel(formatString(item.level));
        setOccupation(item.occupation);
        setQuantity(item.quantity.toString());
        setWorkplace(item.workplace);
        setSex(item.sexual_gender);
        setType(item.employment_type);
        setPerfil(item.perfil);
        setMailContact(item.email);
        setNameContact(item.contact_name);
        setOccupationContact(item.contact_role);
        setPhoneContact(formatarPhone(item.contact_phone));
      }
    } catch (e) {
      alert(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const formatPhoneNumber = (value: string) => {
    if (value.length > 15) return;
    value = value.replace(/\D/g, '');
    value = value.replace(/^(\d{2})(\d)/g, '($1) $2');
    value = value.replace(/(\d)(\d{4})$/, '$1-$2');
    setPhoneContact(value);
  };

  React.useEffect(() => {
    getCurrentVancacie();
  }, []);

  return (
    <Container>
      <MenuBar currentPage="Vagas" />
      <Content>
        <ResponsiveContainer>
          <Header>
            <HeaderBack href="/vagas">
              <BackFullIcon />
            </HeaderBack>
            <HeaderInfo>
              <HeaderTitle>Preencha os campos abaixo </HeaderTitle>
            </HeaderInfo>
          </Header>
          {loading ? (
              <LoadRow>
                <Load
                  customStyle={{
                    color: theme.colors.secondaryDark,
                  }}
                  size="extraLarge"
                />
              </LoadRow>
            ) : (
              <>
                <VacanciesContainer>
                  <InputContainer>
                    <SectionTitle>Dados da vaga</SectionTitle>
                    <InputsVancacieRow>
                      <InputsVancacie
                        customContainerSize="full"
                        customStyle={{ maxWidth: 'none' }}
                        customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                        value={name}
                        setValue={setName}
                        placeholder="Nome da vaga*"
                        warning={warning.name}
                      />
                      <InputsVancacie
                        customContainerSize="half"
                        customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                        value={quantity}
                        setValue={setQuantity}
                        placeholder="Quantidade de vagas*"
                        warning={warning.quantity}
                        type='number'
                      />
                      <InputsVancacie
                        customContainerSize="half"
                        customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                        value={budget}
                        setValue={setBudget}
                        placeholder="Salário"
                        type="number"
                      />
                      <InputSelectComponent
                        customContainerSize="tird"
                        customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                        value={level}
                        setValue={setLevel}
                        placeholder="Nível*"
                        warning={warning.level}
                        selectData={CONST_LEVEL}
                      />
                      <InputSelectComponent
                        customContainerSize="tird"
                        customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                        value={occupation}
                        setValue={setOccupation}
                        placeholder="Cargo*"
                        warning={warning.occupation}
                        selectData={CONST_OCCUPATION}
                      />
                      <InputSelectComponent
                        customContainerSize="tird"
                        customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                        value={sex}
                        setValue={setSex}
                        placeholder="Sexo*"
                        warning={warning.sex}
                        selectData={CONST_SEXUALIDADE}
                      />
                      <InputSelectComponent
                        customContainerSize="tird"
                        customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                        value={priority}
                        setValue={setPriority}
                        placeholder="Prioridade"
                        selectData={CONST_PRIORITYS}
                      />
                      <InputSelectComponent
                        customContainerSize="tird"
                        customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                        value={type}
                        setValue={setType}
                        placeholder="Tipo da vaga*"
                        warning={warning.type}
                        selectData={CONST_VACANCIETYPE}
                      />
                      <InputsVancacie
                        customContainerSize="tird"
                        customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                        value={perfil}
                        setValue={setPerfil}
                        placeholder="Perfil"
                      />
                      <InputsVancacie
                        customContainerSize="half"
                        customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                        value={workplace}
                        setValue={setWorkplace}
                        placeholder="Local"
                      />
                      <InputsVancacie
                        customContainerSize="half"
                        customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                        value={benefits}
                        setValue={setBenefits}
                        placeholder="Benefícios"
                      />
                      <InputsVancacie
                        customContainerSize="full"
                        customStyle={{ maxWidth: 'none' }}
                        customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                        value={keyWord}
                        setValue={setKeyWord}
                        placeholder="Palavras chaves">
                        <ButtonAddKeyWord
                          onClick={() => {
                            let current = keyWordList ? keyWordList : [];
                            if (keyWord && keyWord !== '') current.push(keyWord);
                            setKeyWord('');
                            setKeyWordList(current);
                          }}>
                          <AddIcon />
                        </ButtonAddKeyWord>
                      </InputsVancacie>
                      {keyWordList && keyWordList?.length > 0 && (
                        <KeyWordsArea>
                          {keyWordList.map((e, i) => (
                            <KeyWord
                              key={i}
                              onClick={() => {
                                setKeyWordList(el => {
                                  return el?.filter(item => item !== e)
                                });
                              }}>
                              {e}
                            </KeyWord>
                          ))}
                        </KeyWordsArea>
                      )}
                    </InputsVancacieRow>
                  </InputContainer>
                  <InputContainer>
                    <SectionTitle>Dados de contato</SectionTitle>
                    <InputsContactRow>
                      <InputsVancacie
                        customContainerSize="full"
                        customStyle={{ maxWidth: 'none' }}
                        customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                        value={nameContact}
                        setValue={setNameContact}
                        placeholder="Nome"
                      />
                      <InputsVancacie
                        customContainerSize="tird"
                        customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                        value={mailContact}
                        setValue={setMailContact}
                        placeholder="Email"
                      />
                      <InputsVancacie
                        customContainerSize="tird"
                        customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                        value={phoneContact}
                        setValue={(e: any) => formatPhoneNumber(e)}
                        placeholder="Telefone"
                        isPhone
                      />
                      <InputsVancacie
                        customContainerSize="tird"
                        customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                        value={occupationContact}
                        setValue={setOccupationContact}
                        placeholder="Cargo"
                      />
                    </InputsContactRow>
                  </InputContainer>
                </VacanciesContainer>
                <ButtonRow>
                  <ButtonAddVancacie
                    title="Adicionar"
                    callback={() =>
                      handleUpdateVancancie(
                        name,
                        quantity,
                        level,
                        occupation,
                        budget,
                        sex,
                        priority,
                        type,
                        benefits,
                        perfil,
                        workplace,
                        keyWordList,
                        nameContact,
                        mailContact,
                        phoneContact,
                        occupationContact,
                      )
                    }
                  />
                </ButtonRow>
              </>
            )}
        </ResponsiveContainer>
      </Content>
    </Container>
  );
};

export default EditVancacie;

function formatString(input: string) {
  return input
    .split('/')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join('/');
}
